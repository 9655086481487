import CookiesServices from "./CookiesServices";

import HttpService from "./HttpService";
import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class MechanicServices {
  constructor() {
    const token = CookiesServices.get("token");
    token ? (this.authenticated = true) : (this.authenticated = false);
    this.http = new HttpService();
  }
  async getAllMechanics() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/mechanic`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getLiveVehiclesList() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/livevehicle/getalllivevehicles`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getLocationOfVehicleId(id) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/livevehicle/location`,
        { id },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
}

export default new MechanicServices();
