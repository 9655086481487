import axios from "axios";
import { apiEndpoint } from "../config";

import cookiesService from "./CookiesServices";
const expiresAt = 30;

let axiosConfig = {
  withCredentials: true,
};

class AuthService {
  constructor() {
    const token = cookiesService.get("token");
    token ? (this.authenticated = true) : (this.authenticated = false);
  }
  async ValidateUser(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/adminusers/verify`,
        data,
        axiosConfig
      );
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  isAuthenticated() {
    const token = cookiesService.get("token");
    console.log("isAuthenticated", token);
    token ? (this.authenticated = true) : (this.authenticated = false);
    return true;
  }
  isAuthorised(roleid) {}
  getUserDetails() {
    let data = cookiesService.get("user");
    console.log("getUserDetails", data);
    if (data) {
      return data;
    } else {
      return [];
    }
  }
  handleLoginSuccess(response, remember) {
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + 100 * 60 * 60 * 1000); // 24hrs in milliseconds
    // console.log("test", cookiesService.get("token"));
    // if (!remember) {
    //     const options = { path: "/" };
    //     CookieService.set("access-token", response.token, options);
    //     return true;
    //   }
    console.log("response in handleLoginsuccess:", response);
    let date = new Date();
    date.setTime(date.getTime() + expiresAt * 60 * 1000);
    const options = { path: "/", expires: date };
    // window.localStorage.setItem("token", response.token);
    console.log("Token we got:>" + response[0].token);
    cookiesService.set("token", response[0].token, {
      path: "/",
      expires: expiryDate,
    });
    // cookiesService.set("token", response.token, options);
    cookiesService.set("user", JSON.stringify(response[0]), options);
    return true;
  }
  async logout() {
    try {
      cookiesService.remove("token");
      cookiesService.remove("user");
      cookiesService.remove("data");
      const response = await axios.post(
        `${apiEndpoint}/adminusers/logout`,
        axiosConfig
      );
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
}
export default new AuthService();
