import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import cookiesService from "./services/CookiesServices";

import localStorageObj from "./services/LocalStorageService";
import { apiEndpoint } from "./config";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
const allowedApisWithoutToken = [
  "/adminusers/verify",

  // Add more public endpoints as needed
];
axios.interceptors.request.use(
  async function (config) {
    console.log("axios interceptors");

    const isAllowedApi = allowedApisWithoutToken.some((api) =>
      config.url.includes(api)
    );
    console.log("isAllowedApi", isAllowedApi);
    if (!isAllowedApi) {
      let token = cookiesService.get("token");
      console.log("Token we got:>" + token);
      // console.log(tokenDetails, tokenDetails?.token);
      config.headers["Authorization"] = "Bearer " + token;
      // if (tokenDetails) {
      //   const { token, refreshToken } = tokenDetails;

      //   // if (isTokenExpired(token)) {
      //   //   try {
      //   //     const newToken = await refreshAccessToken(refreshToken);
      //   //     config.headers["Authorization"] = "Bearer " + newToken;
      //   //   } catch (error) {
      //   //     return Promise.reject(error);
      //   //   }
      //   // } else {
      //   //   config.headers["Authorization"] = "Bearer " + token;
      //   // }
      // }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Do something with successful response
    // console.log("Response interceptor:", response);
    return response;
  },
  function (error) {
    // Do something with response error
    // console.error("Response interceptor error:", error);
    return Promise.reject(error);
  }
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
