import "./App.css";
import Layout from "./Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Fragment } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import LoginPage from "../src/LoginPage";
import Dashboard from "../src/pages/general/Dashboard";
import Settings from "./pages/general/Settings";

import Live from "./pages/bookings/LiveBookings";
import Pending from "./pages/bookings/PendingBookings";
import Rejected from "./pages/bookings/RejectedBookings";
import Cancelled from "./pages/bookings/CancelledBookings";
import Failed from "./pages/bookings/FailedBookings";
import History from "./pages/bookings/HistoryBookings";

import All from "./pages/customers/AllCustomers";
import Blocked from "./pages/customers/BlockedCustomers";
import ListVehicles from "./pages/vehicles/LiveVehicles";
import AssignGps from "./pages/vehicles/AssignGps";
import Tracking from "./pages/vehicles/Tracking";
import AllCustomers from "./pages/customers/AllCustomers";
import BlockedCustomers from "./pages/customers/BlockedCustomers";
import AllHosts from "./pages/hosts/AllHosts";
import ApproveHosts from "./pages/hosts/ApproveHosts";
import BlockedHosts from "./pages/hosts/BlockedHosts";
import BlockedVehicles from "./pages/vehicles/BlockedVehicles";
import LiveChat from "./pages/support/LiveChat";
import Mechanic from "./pages/support/Mechanic";
import Complaints from "./pages/complaints/Complaints";
import ProtectionPlans from "./pages/appadmin/ProtectionPlans";
import Coupons from "./pages/appadmin/Coupons";
import PreRegisteredUsers from "./pages/users/PreRegisteredUsers";
import InjectAxiosInterceptors from "./plugin/InjectAxiosInterceptors";
import SessionExpired from "./pages/general/SessionExpired";
import VehicleMake from "./pages/vehicleadmin/VehicleMake";
import VehicleModel from "./pages/vehicleadmin/VehicleModel";
import VehicleCategory from "./pages/vehicleadmin/VehicleCategory";
import VehicleFuelTypes from "./pages/vehicleadmin/VehicleFuelTypes";
import VehicleFeatures from "./pages/vehicleadmin/VehicleFeatures";
import VehicleTransmission from "./pages/vehicleadmin/VehicleTransmission";
import VerifyVehicles from "./pages/vehicles/VerifyVehicles";
import VehicleApproval from "./pages/vehicles/ApprovalPage";
import TotalEarnings from "./pages/payments/TotalEarnings";
import HostEarnings from "./pages/payments/HostEarnings";
import Refunds from "./pages/payments/Refunds";
// import CarrentalDashboard from "./pages/CarrentalDashboard";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <InjectAxiosInterceptors />
        <Fragment>
          <Routes>
            <Route path="/" element={<LoginPage />}>
              <Route path="login" element={<LoginPage />}></Route>
              <Route path="sessiontimeout" element={<SessionExpired />}></Route>
            </Route>

            <Route path="/main" element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="preregistered" element={<PreRegisteredUsers />} />
              <Route path="bookings/live" element={<Live />} />
              <Route path="bookings/pending" element={<Pending />} />

              <Route path="bookings/rejected" element={<Rejected />} />
              <Route path="bookings/cancelled" element={<Cancelled />} />
              <Route path="bookings/failed" element={<Failed />} />
              <Route path="bookings/history" element={<History />} />

              <Route path="customers/all" element={<AllCustomers />} />
              <Route path="customers/blocked" element={<BlockedCustomers />} />

              <Route path="host/approve" element={<ApproveHosts />} />
              <Route path="host/all" element={<AllHosts />} />
              <Route path="host/blocked" element={<BlockedHosts />} />

              <Route path="vehicles/list" element={<ListVehicles />} />
              <Route path="vehicles/verify" element={<VerifyVehicles />} />
              <Route path="vehicles/approval" element={<VehicleApproval />} />
              <Route path="vehicles/assigngps" element={<AssignGps />} />
              <Route path="vehicles/tracking" element={<Tracking />} />
              <Route path="vehicles/blocked" element={<BlockedVehicles />} />

              <Route
                path="payments/totalearnings"
                element={<TotalEarnings />}
              />
              <Route path="payments/hostearnings" element={<HostEarnings />} />

              <Route path="payments/refunds" element={<Refunds />} />

              <Route path="support/livechat" element={<LiveChat />} />
              <Route path="support/mechanic" element={<Mechanic />} />

              <Route path="vadmin/vehiclemake" element={<VehicleMake />} />
              <Route path="vadmin/vehiclemodel" element={<VehicleModel />} />

              <Route
                path="vadmin/vehiclecategory"
                element={<VehicleCategory />}
              />
              <Route
                path="vadmin/vehiclefueltype"
                element={<VehicleFuelTypes />}
              />
              <Route
                path="vadmin/vehiclefeatures"
                element={<VehicleFeatures />}
              />
              <Route
                path="vadmin/vehicletransmission"
                element={<VehicleTransmission />}
              />

              <Route path="complaints" element={<Complaints />} />

              <Route path="aadmin/coupons" element={<Coupons />} />
              <Route
                path="aadmin/protectionplans"
                element={<ProtectionPlans />}
              />

              {/* <Route path="carrentaldboard" element={<CarrentalDashboard />} />
            <Route path="inventory">
            <Route path="drivers">
                <Route path="view" element={<Drivers />}></Route>
                <Route path="profile" element={<DriverProfile />}></Route>
                <Route path="add" element={<AddDrivers />}></Route>
              </Route>
            <Route path="vehicles">
                <Route path="view" element={<Vehicles />}></Route>
                <Route path="profile" element={<VehicleProfile />}></Route>
                <Route path="add" element={<AddVehicle />}></Route>
                <Route path="assigndriver" element={<AssigndriverToVehicle />}></Route>
              </Route>

              <Route path="corporates">
                <Route path="view" element={<Corporates />}></Route>
                <Route path="add" element={<AddCorproate />}></Route>
                <Route path="edit" element={<AddCorproate />}></Route>
              </Route>
              <Route path="geoboundary" element={<CreateGeoboundary />} />
              <Route path="users" element={<Users />} />
            </Route>
            <Route path="invoice">
               <Route path="view" element={<InvoiceList/>}/>
              <Route path="create" element={<CreateInvoice/>}/>
              <Route path="details/:InvoiceNumber" element={<ViewInvoice/>}/>
            </Route>
            <Route path="pricing" element={<PricingModel />} />
            <Route path="pricingV1" element={<PricingModelV1 />} />
            <Route path="tracking">
              <Route path="live" element={<LiveTracking />} />
              <Route path="history" element={<HistoryTracking />} />
            </Route>
            <Route path="booking">
              <Route path="create" element={<CreateBooking />} />
              <Route path="view/:bookingid" element={<BookingView />} />
              <Route path="oldList" element={<Bookings />} />
              <Route path="list" element={<NewBookingList />} />
              <Route path="dispatacher" element={<Dispatchers />} />
            </Route>
            <Route path="servicereminder" element={<ServiceReminder />}></Route>
            <Route path="renewals" element={<VehicleRenewals />}></Route>
            <Route path="reports" element={<Reports />}></Route>
            <Route path="settings" element={<Settings />}></Route>*/}
            </Route>
            {/* <Route path="/admin" element={<CreateOperators />} /> */}
          </Routes>
        </Fragment>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
