import CookiesServices from "./CookiesServices";

import HttpService from "./HttpService";
import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class UserServices {
  constructor() {
    const token = CookiesServices.get("token");
    token ? (this.authenticated = true) : (this.authenticated = false);
    this.http = new HttpService();
  }

  async updateUserDoc(id, doctype, isApproved) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/user/approvedoc`,
        { id, doctype, isApproved },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getUserById(id) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/user/byid`,
        { id },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getAllPregisteredUsers() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/preregistrations`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async generateOTP(mobile, sendvia) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/user/resend`,
        { mobile, sendvia },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getAllCustomers() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/user/1`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getAllHosts() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/user/2`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getBlockedHosts() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/user/2`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getApproveHosts() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/user/2`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getAllBlockedUsers() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/user/blocked`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async blockOrUnBlockUser(id, block) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/user/makeactive`,
        { id: id, isActive: block },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
}

export default new UserServices();
